@keyframes fadeSlideUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wrapper {
    align-items: center;
    display: flex;
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;

    &:hover {
        .background {
            transform: scale(1.2);
        }
    }
}

.background {
    background-position-x: 20%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    transition: transform 10000ms 500ms ease-in;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    @media screen and (min-width: 1024px) {
        background-position-x: 0%;
        background-size: 130%;
    }
}

.content {
    position: relative;
    z-index: 1;
    padding: 6rem 6rem 6rem 4rem;
    color: #ffffff;

    @media screen and (min-width: 1440px) {
        padding: 10rem;
    }

    @media screen and (min-width: 1920px) {
        padding: 20rem;
    }

    h1 {
        -webkit-text-stroke-color: #ffffff;
        -webkit-text-stroke-width: 0.3rem;
        animation: fadeSlideUp 250ms 500ms ease-in both;
        color: #edc23f;
        font-size: 8rem;
        font-weight: 700;
        line-height: 8rem;
        text-transform: uppercase;
        max-width: 7ch;

        @media screen and (min-width: 1280px) {
            font-size: 15rem;
            line-height: 13rem;
        }
        
        @media screen and (min-width: 1440px) {
            font-size: 20rem;
            line-height: 17rem;
        }
    }

    h3 {
        font-size: 3rem;
        font-weight: 600;
        animation: fadeSlideUp 250ms 750ms ease-in both;

        @media screen and (min-width: 1280px) {
            font-size: 4rem;
        }
        
        @media screen and (min-width: 1440px) {
            font-size: 4rem;
        }

        @media screen and (min-width: 1920px) {
            font-size: 6rem;
        }
    }

    strong {
        display: block;
        margin-top: 2rem;
        font-weight: 400;
        animation: fadeIn 1000ms 1000ms ease-in both;

        &::after {
            content: "";
            display: block;
            margin-top: 2rem;
            position: absolute;
            height: 100vh;
            background: white;
            width: 0.4rem;
        }
    }
}

.apps {
    display: flex;
    gap: 3rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 1;

    @media screen and (min-width: 1024px) {
        top: 4rem;
        right: 4rem;
    }

    svg {
        width: 5rem;
        height: 5rem;
    }
}

.app_link {
    color: #ffffff;
    transition: transform 250ms ease-in;
    text-decoration: none;
    
    &:hover {
        transform: scale(1.1);
    }
}

.social {
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
    margin: 0 auto;
    transform: rotate(180deg);
    white-space: nowrap;
    width: fit-content;
    writing-mode: vertical-rl;
    position: absolute;
    top: calc(5rem + 2rem + 4rem);
    right: 2rem;

    @media screen and (min-width: 1024px) {
        top: calc(5rem + 8rem + 4rem);
        right: 4rem;
    }
}

.social_link {
    --icon-primary-color: #ffffff;
    --icon-secondary-color: #C9B9AA;
    align-items: center;
    color: #ffffff;
    display: flex;
    font-size: 1.8rem;
    font-weight: 600;
    gap: 1rem;
    letter-spacing: 1.2px;
    text-decoration: underline solid 0.5rem transparent;
    text-underline-offset: 0.4rem;
    transition: text-decoration 400ms ease-in-out;

    &:hover {
        text-decoration: underline solid 0.5rem currentColor;
    }

    svg {
        width: 2rem;
        height: 2rem;
        transform: rotate(90deg);
    }
}

.logo {
    aspect-ratio: 1990 / 641;
    color: #ffffff;
    height: 6rem;
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    
    @media screen and (min-width: 1024px) {
        bottom: 4rem;
        right: 4rem;
    }

    @media screen and (min-width: 1440px) {
        height: 8rem;
    }
}
