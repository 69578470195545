.wrapper {
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 8rem 2rem 0;
    max-width: 1440px;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        padding: 16rem 4rem 0;
        flex-direction: row;
        gap: 20rem;
    }
}

.small {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    h2 {
        border-bottom: 1rem solid #4c0821;
        color: #4c0821;
        display: block;
        font-size: 4rem;
        width: 100%;
    }

    p {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    img {
        max-width: 70%;
        width: 100%;
    }
}

.large {
    flex-grow: 1;
}

.logo {
    position: relative;
    top: -25%;
    left: -25%;
    color: #edc23f;
    margin-bottom: -10%;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.biography {
    padding: 0 4rem 8rem;
    font-size: 2.5rem;
    margin: 0 auto;
    max-width: 60ch;
    line-height: 4rem;
    text-align: center;

    @media screen and (max-width: 1024px) {
        padding: 8rem 4rem 8rem;
        font-size: 1.8rem;
        line-height: 3.3rem;
    }

    p + p {
        margin-top: 4rem;
    }
}