:root {
    font-size: 62.5%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

*::selection {
    background: #dd4d2a;
}

html,
body {
    box-sizing: border-box;
    overscroll-behavior: none;
    scroll-behavior: smooth;
}

body {
    background-color: #ffffff;
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    height: 100%;
    line-height: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: 'Overpass', sans-serif;
}

svg {
    display: block;
}

img {
    display: block;
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
label {
    display: block;
    margin: 0;
}
