.wrapper {
    margin-bottom: 4rem;
    padding: 2rem;
    width: 100%;

    @media screen and (min-width: 1024px) {
        margin-bottom: 20rem;
    }
}

.container {
    align-items: center;
    background-color: #f7f1d7;
    color: #edc23f;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    margin: 0 auto;
    max-width: 1440px;
    padding: 4rem;
    text-align: center;
    width: 100%;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        padding: 8rem;
    }
}

.item {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
        font-size: 4rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 2rem;
    }

    a {
        color: #edc23f;
        letter-spacing: 1.2px;
        text-decoration: underline solid 0.5rem transparent;
        text-underline-offset: 0.4rem;
        transition: text-decoration 400ms ease-in-out;
    
        &:hover {
            text-decoration: underline solid 0.5rem currentColor;
        }
    }
}