.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin: 0 auto;
    max-width: 1440px;
    padding: 8rem 2rem 4rem;

    @media screen and (min-width: 1024px) {
        padding: 16rem 4rem 8rem;
    }
}

.header {
    color: #4c0821;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;

    @media screen and (min-width: 1024px) {
        align-items: center;
        flex-direction: row;
    }

    img {
        max-width: 250px;
    }
}

.column {
    display: flex;
    gap: 10px;
    flex-direction: column;

    h2 {
        font-size: 40px;
    }
}

.apps {
    align-items: center;
    display: flex;
    gap: 1rem;

    svg {
        width: 4rem;
        height: 4rem;
    }
}

.app_link {
    color: #000000;
    transition: transform 250ms ease-in;
    text-decoration: none;
    
    &:hover {
        transform: scale(1.1);
    }
}

.inner {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4rem;
}

.button {
    border-radius: 100%;
    width: 100px;
    border: 0;
    background-color: #4c0821;
    color: #ffffff;
    height: 100px;
    cursor: pointer;
    padding: 30px;
    transition: transform 150ms ease-in;

    &:hover {
        transform: scale(1.1);
    }
}

:global(#waveform) {
    flex-grow: 1;
}
