.wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.content {
    width: auto;
    flex-grow: 1;

    @media screen and (hover: none) and (max-width: 500px) {
        flex-shrink: 0;
        width: 100vw;
    }

    @media screen and (min-width: 1024px) {
        width: auto;
    }
}
