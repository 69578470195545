.wrapper {
    width: 100%;
    padding: 8rem 0 0 0;
    aspect-ratio: 4 / 3;

    @media screen and (min-width: 1024px) {
        aspect-ratio: 16 / 9;
    }
}

.button {
    --icon-primary-color: #edc23f;
    --icon-secondary-color: #ffffff;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: 0;
    padding: 0;
    transition: transform 150ms ease-in;

    &:hover {
        transform: scale(1.1);
    }

    svg {
        width: 10rem;
        height: 10rem;

        @media screen and (min-width: 1024px) {
            width: 15rem;
            height: 15rem;
        }
    }
}