.wrapper {
    background-color: #f7f1d7;
    color: #4c0821;
    min-height: 100vh;
    width: 8rem;
    flex-shrink: 0;

    @media screen and (min-width: 1280px) {
        width: 12rem;
    }
}

.inner {
    position: sticky;
    top: 0;
}

.logo {
    color: #dd4d2a;
    height: 12rem;
}

.menu {
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
    margin: 0 auto;
    transform: rotate(180deg);
    white-space: nowrap;
    width: fit-content;
    writing-mode: vertical-rl;

    @media screen and (max-width: 768px) {
        gap: 20px;
        margin-top: 1rem;
    }
}

.link {
    color: #4c0821;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-decoration: underline solid 0.5rem transparent;
    text-underline-offset: 0.4rem;
    transition: text-decoration 400ms ease-in-out;

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }

    &:hover {
        text-decoration: underline solid 0.5rem currentColor;
    }
}
